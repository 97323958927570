@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;600&display=swap");

body {
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  margin: 0;
  min-height: 100vh;
  padding: 0;
}

a {
  color: #14A800;
}

p { margin: 0 0 1em; }

strong {
  font-weight: 600;
}

h1 { margin: 0 0 1em; }

.button {
  background-color: #14A800;
  border-radius: 2em;
  color: #FFF;
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
}

.header {
  align-items: center;
  background: linear-gradient(0deg, rgb(210 216 215 / 0%) 0%, rgb(210 216 215 / 100%) 100%);
  clip-path: polygon(0% 0, 0 100%, 100% 85%, 100% 0%);
  display: flex;
  flex-direction: column;
  height: 50vh;
  justify-content: flex-end;
  max-height: 810px;
  overflow: hidden;

}

.name {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2em;

  h1 {
    font-weight: 700;
    margin: 0;
  }
}

.image-container {
  display: flex;
  flex: 1;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.myphoto {
  max-height: 100%;
}

.about {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1em;
}

.contact {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  padding: 1em;

  ul {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;

    li {
      flex: 1;
      line-height: 1.6em;
      list-style: none;
      text-align: center;
    }
  }

  .social {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    flex: 1;
    margin-top: 2em;

    a {
      display: flex;
      flex: 1;
      justify-content: center;
      text-align: center;

      span { display: none; }

      img {
        max-height: 1.2em;
        width: auto;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  body {
    font-size: 1.4em;
  }

  .header {
    flex-direction: row;
    overflow: hidden;
    height: 50vh;
    max-height: 810px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, rgb(210 216 215 / 0%) 0%, rgb(210 216 215 / 100%) 100%);
    clip-path:
      polygon(
        0% 0,
        0 100%,
        100% 85%,
        100% 0%
      );
  }

  .name {
    order: 2;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: auto;
    height: 100%;
    flex: 1;
    padding: 2em;
  }

  .image-container {
    flex: 1;
    width: auto;
    height: 100%;
    position: relative;
    justify-content: flex-end;
    display: flex;
  }

  .myphoto {
    max-height: 100%;
    width: auto;
    position: static;
  }

  .about,
  .contact {
    flex: 1;
    max-width: 960px;
    align-self: center;
    width: 100%;
  }
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #000;
    color: #FFF;
  }

  a {
    color: #FFF;
  }

  .header {
    background-color: #FFF;
    color: #000;
  }
}
