@import "https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;600&display=swap";
body {
  min-height: 100vh;
  background-color: #fff;
  flex-direction: column;
  margin: 0;
  padding: 0;
  font-family: Open Sans, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  display: flex;
}

a {
  color: #14a800;
}

p {
  margin: 0 0 1em;
}

strong {
  font-weight: 600;
}

h1 {
  margin: 0 0 1em;
}

.button {
  color: #fff;
  background-color: #14a800;
  border-radius: 2em;
  padding: .5em 1em;
  text-decoration: none;
  display: inline-block;
}

.header {
  -webkit-clip-path: polygon(0% 0, 0 100%, 100% 85%, 100% 0%);
  clip-path: polygon(0% 0, 0 100%, 100% 85%, 100% 0%);
  height: 50vh;
  max-height: 810px;
  background: linear-gradient(0deg, #d2d8d700 0%, #d2d8d7 100%);
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.name {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em;
  display: flex;
}

.name h1 {
  margin: 0;
  font-weight: 700;
}

.image-container {
  flex: 1;
  justify-content: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.myphoto {
  max-height: 100%;
}

.about {
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  padding: 1em;
  display: flex;
}

.contact {
  box-sizing: border-box;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  padding: 1em;
  display: flex;
}

.contact ul {
  flex-direction: row;
  margin: 0;
  padding: 0;
  display: flex;
}

.contact ul li {
  text-align: center;
  flex: 1;
  line-height: 1.6em;
  list-style: none;
}

.contact .social {
  flex-direction: row;
  flex: 1;
  align-items: flex-end;
  margin-top: 2em;
  display: flex;
}

.contact .social a {
  text-align: center;
  flex: 1;
  justify-content: center;
  display: flex;
}

.contact .social a span {
  display: none;
}

.contact .social a img {
  max-height: 1.2em;
  width: auto;
}

@media screen and (min-width: 1024px) {
  body {
    font-size: 1.4em;
  }

  .header {
    height: 50vh;
    max-height: 810px;
    -webkit-clip-path: polygon(0% 0, 0 100%, 100% 85%, 100% 0%);
    clip-path: polygon(0% 0, 0 100%, 100% 85%, 100% 0%);
    background: linear-gradient(90deg, #d2d8d700 0%, #d2d8d7 100%);
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
    overflow: hidden;
  }

  .name {
    width: auto;
    height: 100%;
    flex-direction: column;
    flex: 1;
    order: 2;
    justify-content: center;
    align-items: flex-start;
    padding: 2em;
    display: flex;
  }

  .image-container {
    width: auto;
    height: 100%;
    flex: 1;
    justify-content: flex-end;
    display: flex;
    position: relative;
  }

  .myphoto {
    max-height: 100%;
    width: auto;
    position: static;
  }

  .about, .contact {
    max-width: 960px;
    width: 100%;
    flex: 1;
    align-self: center;
  }
}

@media (prefers-color-scheme: dark) {
  body {
    color: #fff;
    background-color: #000;
  }

  a {
    color: #fff;
  }

  .header {
    color: #000;
    background-color: #fff;
  }
}

/*# sourceMappingURL=index.6304f88d.css.map */
